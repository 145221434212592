<!-- @format -->

<template>
  <div class="px-6 pb-6">
    <div class="flex flex-col mt-5 gap-2" :key="isWeakDay">
      <label class="text-sm">{{
        complaintStatus === 0
          ? "Enter Acknowledgement Date"
          : extendedDate
          ? "Extension Date"
          : "Enter Date"
      }}</label>
      <DatePicker
        :selectedDate="
          complaintStatus === 0
            ? acknowledgementDate
            : extendedDate
            ? new Date(extendedDate)
            : extensionDate
        "
        :enableWeekendCheck="true"
        @selected-date="atDateSelect"
        @cleared-date="atDateSelect"
        @weakday-selected="atWeakDaySelect"
        :isDisabled="
          complaintStatus === 1
            ? extendedDate
              ? true
              : false
            : complaintStatus === 0
            ? true
            : false
        "
        :needDisableColor="
          complaintStatus === 1 ? (extendedDate ? true : false) : false
        "
        :needMinDate="complaintStatus != 0 ? true : false"
        :minDate="new Date(dueDate)"
        :needMaxDate="complaintStatus != 0 ? true : false"
        :maxDate="minEndDate"
        :placeholder="
          complaintStatus === 0
            ? 'Enter Acknowledgement Date'
            : 'Enter Extension Date'
        "
        :class="isWeakDay ? ' border-red-400' : '  border-gray-200 '"
        class="w-full rounded border"
        :key="isWeakDay"
      />
      <span
        v-if="
          isWeakDay && !(errors.acknowledgementDate || errors.extensionDate)
        "
        class="text-red-500 text-xs"
        :key="isWeakDay"
        >Please Select A Working Day</span
      >
      <span
        v-if="errors.acknowledgementDate || errors.extensionDate"
        class="text-red-500 text-xs"
        >{{
          errors.acknowledgementDate
            ? errors.acknowledgementDate
            : errors.extensionDate
        }}</span
      >
    </div>
    <div class="w-full flex flex-col gap-2 mt-5">
      <label class="text-sm">Complainant Email</label>
      <input
        :value="complainantEmail"
        @change="errors.complainantEmail = null"
        type="email"
        placeholder="Enter Complainant Email"
        :disabled="isEnabled ? false : true"
        class="border border-gray-200 p-2 rounded"
        :class="!isEnabled ? 'isEdit' : ''"
        @blur="validateEmail(complainantEmail)"
      />
    </div>
    <div class="mt-4">
      <label class="text-sm" v-if="complaintStatus === 0"
        >Acknowledgement Letter</label
      >
      <div
        class="flex items-center justify-center border-teal border-dashed border-2 h-32 mt-2 rounded-md cursor-pointer"
        v-bind="getRootProps()"
        v-if="!acceptedFiles && complaintStatus === 0"
      >
        <input v-bind="getInputProps()" />
        <div class="flex items-center">
          Drag or upload files here or
          <div class="cursor-pointer text-teal">&nbsp;Browse here</div>
          <div class="material-icons text-teal pl-1">attachment_outlined</div>
        </div>
      </div>
      <div v-if="acceptedFiles">
        <div
          class="p-4 flex w-full gap-4 rounded-md bg-teal bg-opacity-10 mt-2"
        >
          <div
            class="flex items-center justify-start"
            v-if="complaintStatus === 0 ? (isEnabled ? false : true) : false"
          >
            <span
              v-if="loader"
              class="border-2 border-black rounded-full w-4 h-4 animate-spin"
            ></span>
          </div>
          <div v-for="(item, key) in acceptedFiles" :key="key">
            <div class="w-full bg-teal bg-opacity-25 px-2 rounded-md">
              <div class="text-md line-clamp-2 capitalize flex items-center">
                {{
                  item
                    ? item.name?.length > 35
                      ? item.name?.slice(0, 25) + "..." + item.name?.slice(-8)
                      : item.name
                    : ""
                }}
                <span
                  class="material-icons w-5 text-red px-1 cursor-pointer text-lg"
                  v-if="!loader"
                  @click="removeSingleFile(key)"
                  >close</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <small v-if="errors.acceptedFiles" class="text-red">{{
      errors.acceptedFiles
    }}</small>
    <small v-if="!isFileUploaded.flag" class="text-red">{{
      isFileUploaded.msg
    }}</small>
    <div class="flex justify-end pt-4" v-if="!extendedDate">
      <button
        class="py-3 px-10 text-white rounded-md font-light"
        @click="saveForm"
        :class="loader ? 'bg-teal bg-opacity-10 cursor-wait' : 'bg-teal'"
        :disabled="loader"
      >
        Save
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, toRefs, computed } from "vue";
import { useDropzone } from "vue3-dropzone";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import SuccessIcon from "@/assets/icons/success.svg";
import Swal from "sweetalert2";
import DatePicker from "@/components/newUi/atoms/DatePicker.vue";
const { getRootProps, getInputProps, ...rest } = useDropzone({
  onDrop,
  maxSize: 5242880,
  maxFiles: 1,
  accept: [".pdf", ".docx"],
});

const props = defineProps({
  complaintStatus: {
    type: Number,
    required: true,
  },
  isEnabled: {
    type: Boolean,
    required: true,
  },
  complainantEmail: {
    type: String,
    required: true,
  },
  dueDate: {
    type: Date,
    default: new Date(),
  },
  extendedDate: {
    type: Date,
    default: null,
  },
});

const route = useRoute();
const store = useStore();
const { complaintStatus, isEnabled, complainantEmail, dueDate, extendedDate } =
  toRefs(props);
const restVlaue = ref(null);
const complaintId = ref(route.params.complaintId);
const date = new Date();
const router = useRouter();
const extensionDate = ref(null);
const loader = ref(false);
const acceptedFiles = ref(null);
const isWeakDay = ref(false);
const isFileUploaded = ref({ flag: true, msg: "" });
const errors = ref({
  acknowledgementDate: null,
  extensionDate: null,
  acceptedFiles: null,
});
const minEndDate = computed(() => {
  if (dueDate.value) {
    const dueD = new Date(dueDate.value);
    return add_months(dueD, 1);
  }
  return null;
});
const atWeakDaySelect = () => {
  isWeakDay.value = true;
};
const add_months = (dt, n) => {
  return new Date(dt.setMonth(dt.getMonth() + n));
};
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const acknowledgementDate = ref(formatDate(date));

const atDateSelect = (selectedDate) => {
  errors.value.extensionDate = false;
  if (selectedDate) {
    isWeakDay.value = false;

    complaintStatus.value === 0
      ? (acknowledgementDate.value = selectedDate)
      : (extensionDate.value = selectedDate);
  } else {
    complaintStatus.value === 0
      ? (acknowledgementDate.value = null)
      : (extensionDate.value = null);
  }
};

function onDrop(acceptedFilesNew, rejectReasons) {
  errors.value.acceptedFiles = "";
  if (acceptedFiles.value) {
    if (acceptedFiles.value?.length + acceptedFilesNew.length > 1) {
      isFileUploaded.value.flag = false;
      isFileUploaded.value.msg = "You can upload 1 file at max!";
    } else {
      acceptedFiles.value = [...acceptedFiles.value, ...acceptedFilesNew];
      isFileUploaded.value.flag = true;
      isFileUploaded.value.msg = "";
      if (rejectReasons) {
        rejectReasons.map((item) => {
          if (item.errors[0].message.includes("Too many files")) {
            isFileUploaded.value.flag = false;
            isFileUploaded.value.msg = "You can upload 1 file at max!";
          }
          if (
            item.errors[0].message.includes("File is larger than 5242880 bytes")
          ) {
            isFileUploaded.value.flag = false;
            isFileUploaded.value.msg = "File Size Must Be Smaller Than 5MB";
          }
        });
      }
      restVlaue.value = rest;
    }
  } else {
    acceptedFiles.value = acceptedFilesNew;
    isFileUploaded.value.flag = true;
    isFileUploaded.value.msg = "";
    if (rejectReasons) {
      rejectReasons.map((item) => {
        if (item.errors[0].message.includes("Too many files")) {
          isFileUploaded.value.flag = false;
          isFileUploaded.value.msg = "You can upload 1 file at max!";
          acceptedFiles.value = null;
        }
        if (
          item.errors[0].message.includes("File is larger than 5242880 bytes")
        ) {
          isFileUploaded.value.flag = false;
          isFileUploaded.value.msg = "File Size Must Be Smaller Than 5MB";
          acceptedFiles.value = null;
        }
      });
    }
    restVlaue.value = rest;
  }
}

const removeSingleFile = (id) => {
  if (acceptedFiles.value.length === 1) {
    acceptedFiles.value = null;
  } else {
    acceptedFiles.value.splice(id, 1);
  }
};

const saveForm = () => {
  if (complaintStatus.value === 0 && !acknowledgementDate.value) {
    errors.value.acknowledgementDate = "Acknowledgement Date is required.";
  } else if (complaintStatus.value !== 0 && !extensionDate.value) {
    errors.value.extensionDate = "Extension Date is required.";
  }

  if (!acceptedFiles.value && complaintStatus.value === 0) {
    errors.value.acceptedFiles = "You must upload at least one file.";
  }

  if (
    !errors.value.acknowledgementDate &&
    !errors.value.extensionDate &&
    !errors.value.acceptedFiles
  ) {
    const formData = new FormData();
    let endPoint = "";
    let title = "";
    if (complaintStatus.value === 0) {
      formData.append("acknowledgement_date", acknowledgementDate.value);
      acceptedFiles.value.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      title = "Complaint Acknowledged";
      endPoint = "hqComplaints/ackhnowledgeComplaint";
    } else {
      formData.append("extension_date", extensionDate.value);
      title = "Extension Granted";
      endPoint = "hqComplaints/askExtension";
    }
    loader.value = true;
    formData.append("complaint", complaintId.value);
    store
      .dispatch(endPoint, formData)
      .then(() => {
        loader.value = false;
        Swal.fire({
          toast: true,
          position: "top-end",
          title: title,
          iconHtml: `<img src="${SuccessIcon}" />`,
          width: "32em",
          customClass: {
            title: "popup-title",
            popup: "popup-border",
            content: "popup-text",
            icon: "popup-icon-border",
          },
          didOpen: () => {
            router.push({
              name: "hq-monitoringOfPracticeFunctionComplaintsDashboard",
            });
          },
          timer: 2000,
          showConfirmButton: false,
          background: `rgba(225, 249, 240, 1)`,
        });
      })
      .catch(() => {
        loader.value = false;
      });
  } else {
    return;
  }
};
</script>

<style scoped>
.isEdit {
  background: rgba(231, 243, 242, 0.65);
}
</style>
