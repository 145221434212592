<!-- @format -->

<template>
  <div class="bg-white rounded-lg py-3">
    <div class="p-6 animate-pulse" v-if="loader && !isEnabled">
      <div class="flex items-center justify-between">
        <div>
          <div class="flex gap-2">
            <div
              class="w-32 h-4 bg-gray-200 rounded-full dark:bg-gray-400"
            ></div>
            <div
              class="w-32 h-4 bg-gray-200 rounded-full dark:bg-gray-400"
            ></div>
          </div>
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-24 mt-2.5"
          ></div>
          <div class="flex gap-2">
            <div v-for="loader in tabsData?.length" :key="loader">
              <div
                class="w-16 h-3 bg-gray-200 rounded-full dark:bg-gray-400 mt-10"
              ></div>
            </div>
          </div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-52"></div>
      </div>
      <div class="h-0.5 mt-3 bg-gray-200 rounded-full dark:bg-gray-400"></div>
    </div>
    <div v-else>
      <div class="p-6 text-xl font-medium" v-if="isEnabled && !isEditable">
        Create New Complaint
      </div>
      <div v-else>
        <div class="flex p-6 justify-between items-center">
          <div>
            <div class="flex gap-2">
              <div class="text-xl font-medium">Complaint</div>
              <div class="px-2 py-0.5 rounded-md" :class="status?.style">
                {{ status?.title }}
              </div>
            </div>
            <div class="text-gray-500 font-light">
              {{ complaint?.reference_number }}
            </div>
          </div>
          <div v-if="complaint.status === 0 && tabIndex === 0 && !isEditable">
            <button
              class="border border-teal hover:bg-teal hover:text-white text-teal px-4 py-2 rounded-md mx-2"
              @click="editComlaintForm()"
            >
              Edit
            </button>
            <button
              class="border border-red hover:bg-red hover:text-white text-red px-4 py-2 rounded-md"
              @click="deleteComplaint(complaint?.id)"
            >
              Delete
            </button>
          </div>
          <div v-else-if="complaint.status === 1 && tabIndex <= 1">
            <div class="font-medium text-xl">
              <span class="text-gray-500">Remaining: </span>
              <span class="text-red"
                >{{
                  complaint?.complaint_acknowledgement?.remaining_days
                }}
                Working Days</span
              >
            </div>
          </div>
          <div v-else-if="complaint.status === 1 && tabIndex === 2">
            <div class="font-medium text-xl">
              <span class="text-gray-500">Due Date: </span>
              <span class="text-red">{{
                moment(
                  complaint?.complaint_acknowledgement?.extension_date
                    ? complaint?.complaint_acknowledgement?.extension_date
                    : complaint?.complaint_acknowledgement?.due_date
                ).format("MMM DD, YYYY")
              }}</span>
            </div>
          </div>
        </div>
        <div class="drop-shadow-2xl w-full px-6 pb-2" v-if="!isEditable">
          <Tabs
            :key="tabsData"
            :tabsData="tabsData"
            :activeTabIndex="tabIndex"
            @index="switchTab"
          />
        </div>
      </div>
    </div>

    <div>
      <CreateViewComplaintForm
        v-if="tabIndex === 0"
        :loader="loader"
        :isEnabled="isEnabled"
        :staffGroup="staffGroup"
        :subjectArea="subjectArea"
        :complaint="complaint"
        :isEditable="isEditable"
        :practices="practices"
        @at-cancel-clicked="resetComplaintForm"
        @after-edit-response="complaintFormEdited"
      />
      <HqComplaintAcknowledgementExtension
        v-if="
          (tabIndex === 1 && complaint?.status === 0) ||
          (tabIndex === 2 && complaint?.status === 1)
        "
        :complaintStatus="complaint.status"
        :isEnabled="isEnabled"
        :complainantEmail="complaint.complainant_email"
        :dueDate="complaint?.complaint_acknowledgement?.due_date"
        :extendedDate="complaint?.complaint_acknowledgement?.extension_date"
      />
      <HqComplaintsUpdates
        class="pt-4"
        v-if="tabIndex === 1 && complaint?.status >= 1"
        :complaintStatus="complaint.status"
        :complaintUpdates="complaint.complaint_update"
        :key="complaint.complaint_update"
      />
      <ComplaintOutcomeForm
        v-if="
          (tabIndex === 3 && complaint?.status == 1) ||
          (complaint.status > 1 && tabIndex === 2)
        "
        :isEnabled="isEnabled"
        :complaintStatus="complaint.status"
        :outcome="complaint?.complaint_outcome"
        :key="complaint?.complaint_outcome"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import { useStore } from "vuex";
import icon from "@/assets/icons/error.svg";
import SuccessIcon from "@/assets/icons/success.svg";
import Swal from "sweetalert2";
import Tabs from "@/components/newUi/baseComponents/Tabs.vue";
import CreateViewComplaintForm from "@/components/newUi/Complaints/CreateViewComplaintForm.vue";
import HqComplaintsUpdates from "./HqComplaintsUpdates.vue";
import HqComplaintAcknowledgementExtension from "./HqComplaintAcknowledgementExtension.vue";
import ComplaintOutcomeForm from "@/components/newUi/Complaints/ComlpaintOutcomeForm.vue";

const route = useRoute();
const router = useRouter();
const isEnabled = ref(parseInt(route.params.isEnabled));
const complaintId = ref(route.params.complaintId);
const isEditable = ref(false);
const loader = ref(true);
const store = useStore();
const staffGroup = ref([]);
const subjectArea = ref([]);
const practices = ref([]);
const tabIndex = ref(0);
const status = computed(() => {
  switch (complaint.value?.status) {
    case 0:
      return { title: "Open", style: "text-green  bg-green bg-opacity-10 " };
    case 1:
      return {
        title: "In Progress",
        style: "text-blue  bg-blue bg-opacity-10",
      };

    case 2:
      return { title: "Upheld", style: "text-white bg-gray-800 " };
    case 3:
      return {
        title: "Partially Upheld",
        style: "text-black bg-gray-800 bg-opacity-10",
      };
    case 4:
      return {
        title: "Not Upheld",
        style: "text-white bg-gray-800 bg-opacity-10",
      };
    default:
      return "Unknown";
  }
});

const switchTab = (index) => {
  if (complaint.value?.status == 0) {
    isEnabled.value = 0;
    isEditable.value = false;
  }
  tabIndex.value = index;
};

const tabsData = computed(() => {
  switch (status.value?.title) {
    case "Open":
      return [
        {
          name: "Details",
          value: 0,
        },
        {
          name: "Acknowledgement",
          value: 1,
        },
      ];
    case "In Progress":
      return [
        {
          name: "Details",
          value: 0,
        },
        {
          name: "Updates",
          value: 1,
        },
        {
          name: "Extension",
          value: 0,
        },
        {
          name: "Outcomes",
          value: 1,
        },
      ];
    case "Upheld":
    case "Not Upheld":
    case "Partially Upheld":
      return [
        {
          name: "Details",
          value: 0,
        },
        {
          name: "Updates",
          value: 1,
        },
        {
          name: "Outcomes",
          value: 1,
        },
      ];
    default:
      return [
        {
          name: "Details",
          value: 0,
        },
        {
          name: "Updates",
          value: 1,
        },
        {
          name: "Outcomes",
          value: 1,
        },
      ];
  }
});

const complaint = computed(() => {
  return store.getters["hqComplaints/getComplaint"];
});

const editComlaintForm = () => {
  isEnabled.value = 1;
  isEditable.value = true;
};

const resetComplaintForm = (flag) => {
  isEnabled.value = 0;
  isEditable.value = flag;
};

const complaintFormEdited = (flag) => {
  isEnabled.value = 0;
  isEditable.value = flag;
};

const deleteComplaint = (complaintId) => {
  Swal.fire({
    width: "35%",
    iconHtml: `<img src="${icon}" />`,
    title: `Do you really want to delete this complaint? `,
    showCancelButton: true,
    cancelButtonText: "Cancel",
    confirmButtonText: "Confirm",
    reverseButtons: true,
    confirmButtonColor: "rgba(0, 164, 153, 1)",
    customClass: {
      title: "custom-delete-title",
      popup: "border-radius",
      content: "custom-text",
      icon: "icon-border",
      cancelButton: "custom-cancel-button",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      store
        .dispatch("hqComplaints/deleteComplaint", {
          complaintId: complaintId,
        })
        .then(() => {
          Swal.fire({
            toast: true,
            position: "top-end",
            title: `Complaint Has Been Deleted Successfully`,
            iconHtml: `<img src="${SuccessIcon}" />`,
            width: "32em",
            customClass: {
              title: "popup-title",
              popup: "popup-border",
              content: "popup-text",
              icon: "popup-icon-border",
            },
            didClose: () => {
              router.push({
                name: "hq-monitoringOfPracticeFunctionComplaintsDashboard",
              });
            },
            timer: 2000,
            showConfirmButton: false,
            background: `rgba(225, 249, 240, 1)`,
          });
        });
    }
  });
};

onMounted(async () => {
  await store.dispatch("hqComplaints/loadStaffGroup").then((response) => {
    staffGroup.value = response;
  });
  await store.dispatch("hqComplaints/loadSubjectArea").then((response) => {
    subjectArea.value = response;
  });
  await store.dispatch("hqComplaints/loadPractices").then((response) => {
    practices.value = response;
  });

  if (!isEnabled.value) {
    await store.dispatch("hqComplaints/fetchSingleComplaint", {
      complaintId: complaintId.value,
    });
  }

  loader.value = false;
});
</script>
